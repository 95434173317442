var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(_vm.computedComponentName,{tag:"component",attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-between filter-title",class:{ 'font-weight-bold': _vm.isFilterApplied }},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" flag ")]),_vm._v(" Location ")],1),_c('v-icon',[_vm._v(" expand_more ")])],1)]}}]),model:{value:(_vm.shouldShowModal),callback:function ($$v) {_vm.shouldShowModal=$$v},expression:"shouldShowModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-md-none"},[_vm._v(" Location ")]),_c('v-card-text',{staticClass:"pt-md-3",class:{ 'black--text': _vm.isThemeLight }},[_c('v-row',_vm._l((_vm.computedKinds),function(kind){return _c('v-col',{key:kind,attrs:{"cols":"12","md":_vm.computedKinds.length === 1 ? 12 : 6}},[_c('div',{staticClass:"mb-1 d-flex"},[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('v-icon',{staticClass:"mr-1",class:{ 'black--text': _vm.isThemeLight },attrs:{"left":""}},[_vm._v(" "+_vm._s(kind === "audience" ? "people" : "volume_down")+" ")]),_vm._v(" "+_vm._s(_vm.computedKinds.length > 1 ? _vm.capitalizeString(kind) : "Location")+" ")],1),(_vm.showTooltips)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.isThemeLight ? 'black' : null}},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltipTexts[kind])+" ")])]):_vm._e()],1),_c('v-autocomplete',{attrs:{"items":_vm.searchItems,"label":_vm.onlyCountries ? 'Country' : _vm.onlyCities ? 'City' : 'City or Country',"dense":"","eager":"","outlined":"","append-icon":null,"return-object":"","hide-details":"","item-text":"name","item-value":"id","full-width":""},on:{"change":function($event){return _vm.submitForm(kind)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center py-2"},[_vm._v("No results found.")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"ripple":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-2"},[_c('v-img',{attrs:{"height":"13","width":"20","src":_vm.proxyUrl(("https://flagcdn.com/w20/" + (item.country_code.toLowerCase()) + ".png"), true)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"first-letter-uppercase"},[_vm._v(" "+_vm._s(item.type)+" ")])],1)],1)]}}],null,true),model:{value:(_vm.form[kind]),callback:function ($$v) {_vm.$set(_vm.form, kind, $$v)},expression:"form[kind]"}}),(_vm.allowMultiple && _vm.appliedFiltersFor[kind].length)?_c('div',{staticClass:"mt-3"},_vm._l((_vm.appliedFiltersFor[kind]),function(item){return _c('div',{key:item.id},[_c('div',{staticClass:"d-flex justify-space-between align-center pb-1 pt-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.removeFilterItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" clear ")])],1),_vm._v(" "+_vm._s(item.data.inputs.name)+" ")],1),(kind === 'audience')?_c('weight-selector',{on:{"change":function($event){return _vm.updateFilterItem(item)}},model:{value:(_vm.weightInput[item.timestamp]),callback:function ($$v) {_vm.$set(_vm.weightInput, item.timestamp, $$v)},expression:"weightInput[item.timestamp]"}}):_vm._e()],1)])}),0):_vm._e()],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }